<template>
  <div class="experiences" data-view>
    <hero
      title="neera vibes"
      description="
    <p>
      explore more at
      <span class='whitespace-nowrap saoltext'>‘neera retreat hotel’</span> a mindfully
      <span class='whitespace-nowrap'>eco-concept</span> hotel right on the <span class='whitespace-nowrap'>Tha Chin River</span>.
    </p>
    "
      class="my-18 lg:mb-20 lg:mt-8"
    />
    <tab-menu
      :items="galleries"
      :default-tab="defaultTabId"
      path-name="galleries"
      path-id="gallery_id"
      :has-all="false"
    />
    <div id="gallery-tab" class="flex flex-col mb-18 lg:mb-20">
      <div
        v-for="(item, index) in galleries"
        :key="index"
        :id="`gallery-${item.id}`"
      >
        <quote-break data-aos="fade-up" class="my-18 lg:my-20">
          <template #title>
            <div class="space-y-4">
              <p
                class="text-np-56 lg:text-np-3xl saoltext font-light text-np-glimpse"
              >
                {{ item.name }}
              </p>
              <p
                v-if="item.description"
                class="text-np-base font-light text-np-green robototext"
              >
                {{ item.description }}
              </p>
            </div>
          </template>
        </quote-break>
        <gallery-grid
          data-aos="fade-up"
          v-if="item.grid.length >= 3"
          :photos="item.grid"
          class="mb-4 lg:my-8"
        />
        <gallery-slide
          data-aos="fade-up"
          v-if="item.slide.length > 0"
          :images="item.slide"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getGallery } from "@/api/endpoints/gallery";
import to from "await-to-js";
import Hero from "@/components/Hero.vue";
import TabMenu from "@/components/TabMenu.vue";
import { getImageUrl } from "@/utils/imageUtils.js";
import QuoteBreak from "../../components/QuoteBreak.vue";
import AOS from "aos";
import GalleryGrid from "./components/GalleryGrid.vue";
import GallerySlide from "@/components/gallery/GallerySlide.vue";

export default {
  name: "Gallery",
  components: {
    Hero,
    TabMenu,
    QuoteBreak,
    GalleryGrid,
    GallerySlide,
  },
  created() {
    const title = "neera vibes";
    const description =
      "explore more at ‘neera retreat hotel’ a mindfully eco-concept hotel right on the Tha Chin River.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  data() {
    return {
      lang: "en",
      galleries: [],
      defaultTabId: 0,
    };
  },
  watch: {
    defaultTabId: {
      handler: async function (tabId) {
        if (tabId && document.getElementById("gallery-" + tabId)) {
          document.getElementById("gallery-" + tabId).scrollIntoView();
        }
      },
      deep: true,
      immediate: true,
    },
    "$route.query.gallery_id": {
      handler: async function (gallery_id) {
        console.log("scroll to " + gallery_id);
        if (gallery_id) {
          document.getElementById("gallery-" + gallery_id).scrollIntoView();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });
    await this.setGallery(this.lang);
  },
  methods: {
    getImageUrl,
    async setGallery(lang) {
      const [err, response] = await to(getGallery(lang));

      if (err) {
        return { errorMessage: err?.response?.data?.error || err.message };
      }
      let galleries = response.data.data.attributes.Showcases;

      let mapGalleries = [];
      galleries.forEach((c) => {
        let imageGrid = [];
        let imageSlide = [];

        if (this.defaultTabId == 0) this.defaultTabId = c.id;

        if (c.images.data.length >= 3) {
          imageGrid = c.images.data.slice(0, 3).map((i) => {
            return {
              image_url: getImageUrl(i),
            };
          });
          imageSlide = c.images.data.slice(3, c.images.length).map((i) => {
            return {
              image_url: getImageUrl(i),
            };
          });
        } else {
          imageSlide = c.images.data.map((i) => {
            return {
              image_url: getImageUrl(i),
            };
          });
        }

        mapGalleries.push({
          id: c.id,
          name: c.name,
          description: c.description,
          grid: imageGrid,
          slide: imageSlide,
        });
      });
      // for (const key1 in galleries) {
      //   if (Object.hasOwnProperty.call(galleries, key1)) {
      //     let element1 = galleries[key1];
      //     let images = element1.images.data;
      //     let total = images.length;

      //     if (this.defaultTabId == 0) this.defaultTabId = element1.id;

      //     if (total > 3) {
      //       element1.thumb = images.splice(0, 3);
      //       if (images.length > 3) element1.images = images.splice(0, 3);
      //       else element1.images = images;
      //     } else {
      //       element1.thumb = [];
      //       element1.images = [];
      //     }
      //     this.galleries.push(element1);
      //   }
      // }
      this.galleries = mapGalleries;

      console.log(this.galleries);
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #994e2e;
}

.content {
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.img-corner {
  border-radius: 0px 80px 0px 0px;
}
</style>
