<template>
  <div class="max-w-screen-lg mx-auto flex flex-col px-4 lg:px-8">
    <div class="grid grid-rows-2 grid-flow-col gap-4 lg:gap-8">
      <div class="relative .aspect-4p5-3 flex w-full h-full">
        <img
          class="absolute object-cover w-full h-full"
          :src="photos[1].image_url"
        />
      </div>
      <div class="relative .aspect-4p5-3 flex w-full h-full">
        <img
          class="absolute object-cover w-full h-full"
          :src="photos[2].image_url"
        />
      </div>
      <div class="relative aspect-4-3 flex w-full h-full row-span-2">
        <img
          class="absolute object-cover w-full h-full rounded-tr-neera lg:rounded-tr-np-lg"
          :src="photos[0].image_url"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GalleryGrid",
  props: {
    photos: {
      type: Array,
    },
  },
};
</script>
